import React, { useEffect, useState } from "react";
import { logoutUser } from "./actions/authActions"; 
import { IdleTimeoutManager } from "idle-timer-manager";
import { connect } from "react-redux"; 
const Child = ({logoutUser}) => {
  const [text, setText] = useState("Child")
 
  useEffect(() => {
    const manager = new IdleTimeoutManager({
      timeout: 3600, //expired after 10 secs
      onExpired: (time) => {
        logoutUser();
      }
    });
 
    return () => {
      manager.clear();
    }
  }, []);
 
  return <div></div>
}
export default connect(
 null,   { logoutUser }
  )(Child);
