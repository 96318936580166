import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import ReactLoading from "react-loading";
import Map from "./Map"
import {Row,Col,Container} from "react-bootstrap"
const filterd = [
  {
    accountType: 'Customer - RS',
    id: 1,
  },
  {
    accountType: 'Customer - SS',
    id: 2,
  },
  {
    accountType: 'Customer - Supply',
    id: 3,
  },
  {
    accountType: 'Customer - RS & SS',
    id: 4,
  },
  {
    accountType: 'Customer - RS & Supply',
    id: 5,
  },
  {
    accountType: 'Customer - SS & Supply',
    id: 6,
  },
  {
    accountType: 'Customer - RS & SS Ongoing',
    id: 7,
  },
  {
    accountType: 'Customer - RS & SS & Supply',
    id: 8,
  },
{
    accountType: 'RS',
    id: 9,
  },
  {
    accountType: 'SS',
    id: 8,
  },
  {
    accountType: 'SS Ongoing',
    id: 8,
  },
  {
    accountType: 'Supply',
    id: 8,
  }, 
 
];

class Dashboard extends Component {
state = {
        pos : null,
        shelters: [],
        filterd:false,
        filterValues:[],
 textfilter:[],
        selectfilter:[],
        textfil:false,
        selectfil:false,
        accountTypes:"",
        selectedvalue:"",
	loading:true,
    };  

onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

filterData = e => {
     
  var newdata = [];
 if(this.state.selectfil){
    newdata=this.state.selectfilter.filter(el => {
      const lc = el.accountname.toLowerCase();

      const filter = e.target.value.toLowerCase();

      return lc.includes(filter)
    });
    if(e.target.value.length>0){
      this.setState({filterValues:newdata,textfilter:newdata,filterd:true,textfil:true})
    }else{
      this.setState({filterd:false,textfil:false})
    }
  }else{
    newdata=this.state.shelters.filter(el => {
      const lc = el.accountname.toLowerCase();

      const filter = e.target.value.toLowerCase();

      return lc.includes(filter)
    });
    if(e.target.value.length>0){
      this.setState({textfilter:newdata,textfil:true})
    }else{
      this.setState({textfil:false})
    }
  }
}

componentDidMount() {
    fetch("https://map.onecleanfamily.com/api/v1/admin/customer")
    .then(r => r.json())
    .then(data => {
      console.log(data)
      this.setState({ shelters: data.data })
    })
    let pos=null
    navigator.geolocation.getCurrentPosition(function(position) {
      
     // console.log("Latitude is :", position.coords.latitude);
     // console.log("Longitude is :", position.coords.longitude);
      pos=position.coords
    });
    setTimeout(()=>{
      this.setState({
        pos,loading:false
    });
    console.log(this.state)
      
    },1000)
    
  }

  onChange=e=>{
    if(e.target.value=="any"){
      this.setState({filterd:false,selectfil:false})
      console.log("empty"+this.state)
    }
    else{
 //     const fil = this.state.shelters.filter(custom=>custom.accountType==e.target.value)
 if(this.state.textfil){
    const fil=this.state.textfilter.filter(el => {
      const lc = el.accountType.toLowerCase();
  
      const filter = e.target.value.toLowerCase();
  
      return lc.includes(filter)
    });
        this.setState({filterValues:fil,selectfilter:fil})
        this.setState({filterd:true,selectfil:true})
        
   }else{
 const fil=this.state.shelters.filter(el => {
    const lc = el.accountType.toLowerCase();

    const filter = e.target.value.toLowerCase();

    return lc.includes(filter)
  });
      this.setState({filterValues:fil,selectfilter:fil})
      this.setState({filterd:true,selectfil:true})
      
      }
      console.log(this.state)

    }
   
  }
  render() {
    const { user } = this.props.auth;
  const { textfil,textfilter,selectfil,selectfilter,shelters,filterValues } = this.state;
    return (<div>
            <Container>
      <Row 
    >
<Col >

<label style={{color:"black",fontSize:"17px"}}>Search: </label>
 
      <input
        placeholder="Search By Account"
  
        style={{width: 300}}
        onChange={e => this.filterData(e)}
      />
      </Col>
 <Col  >

 <label style={{color:"black",fontSize:"17px"}}>Show: </label>
      <select style={{display:"inline",width:300}} onChange={this.onChange}>
              <option value="any">All Account Types</option>
              {filterd.map(cuisine => {
                return <option key={cuisine.id} value={cuisine.accountType}>{cuisine.accountType}</option>;
              })}
            </select>
</Col>
            </Row>
    </Container>
   
    
   {this.state.loading === true? <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> <ReactLoading type='spokes' color="#DC143C" /></div> : <Map pos={this.state.pos} shelters={textfil==true && selectfil==true ?filterValues: textfil==true && selectfil==false?textfilter:textfil==false && selectfil==true?selectfilter:shelters }/>}
           </div>
    );
  }
}
Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  { logoutUser }
)(Dashboard);

/*    <div style={{ height: "75vh" }} className="container valign-wrapper">
        <div className="row">
          <div className="col s12 center-align">
            <h4>
              <b>Hey there,</b> {/*user.name.split(" ")[0]}
              <p className="flow-text grey-text text-darken-1">
                You are logged into a full-stack{" "}
                <span style={{ fontFamily: "monospace" }}>MERN</span> app 👏
              </p>
            </h4>
            <button
              style={{
                width: "150px",
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginTop: "1rem"
              }}
              onClick={this.onLogoutClick}
              className="btn btn-large waves-effect waves-light hoverable blue accent-3"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
  */
