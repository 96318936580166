import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

class Navbar extends Component {
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    return (
      <div className="navbar-fixed">
        <nav className="z-depth-0">
          <div style={{width:"100%"}} className="nav-wrapper white">
            <Link
              to="/"
              style={{
                fontFamily: "monospace",
                fontSize:"25px"
            
              }}
              className="brand-logo center black-text"
            >
             
              OneFamily Map
            </Link>
   {this.props.auth.isAuthenticated?<Link to="/" className="col s5 black-text" onClick={this.onLogoutClick}>
            Logout
   
            </Link>:""} 
        

          </div>
          
        </nav>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(Navbar);
